<template>
	<div>
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:fields="PRODUCT_IMPORT_HISTORY_FIELDS"
					:items="productImportHistories"
					class="table-custom table-product"
					clickable-rows
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "product import histories" }) }}
							</p>
						</div>
					</template>
					<template #file="{item}">
						<CLink
							v-if="item"
							variant="ghost"
							color="secondary"
							@click="downloadOriginalFile(item.url)"
						>
							{{ item.name }}
						</CLink>
					</template>
					<template #logfile="{item}">
						<CLink
							v-if="item"
							variant="ghost"
							color="secondary"
							@click="downloadLog(item)"
						>
							Download
						</CLink>
						<template v-else>
							-
						</template>
					</template>
					<template #status="{item}">
						<CBadge
							:color="item.value"
							class="badge-status"
						>
							{{ item.name }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>
<script>
// import { mapState } from "vuex";
import { mapGetters, mapActions, mapState } from 'vuex';
import { PRODUCT_IMPORT_HISTORY_FIELDS } from '../enums/productImportHistory';
import { downloadFileAPI } from '../services/api/productImportHistories.api';

export default {
	name: 'ProductImportHistory',
	data() {
		return {
			PRODUCT_IMPORT_HISTORY_FIELDS,
		};
	},
	computed: {
		...mapState('products', {
			import: 'import',
		}),
		...mapGetters({
			productImportHistories: 'products/productImportHistoryList',
		}),
		isLoading() {
			return this.import.isLoading;
		},
	},
	async created() {
		await this.getProductImportHistories();
	},
	methods: {
		...mapActions({
			getProductImportHistories: 'products/getProductImportHistories',
		}),
		downloadOriginalFile(originalUrl) {
			downloadFileAPI(originalUrl);
		},
		downloadLog(logUrl) {
			downloadFileAPI(logUrl);
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-search {
		background: transparent;
		box-shadow: none;
		border: none;
		padding: 0;
	}
</style>
